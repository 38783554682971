import serverAxios from "..";

export const login = (data) => {
	return serverAxios({
		method: "post",
		url: "user/login",
		data: data
	})
}


// 查询商家
export const getUserBy = () => {
	return serverAxios({
		method: "get",
		url: "user/getUserBy"
	})
}

// 查询商家
export const generate = (data) => {
	return serverAxios({
		method: "post",
		url: "api/pdf/generate",
		data: data
	})
}