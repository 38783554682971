<template>
	<div class="spinner-overlay" v-if="visible">
		<div class="spinner-container">
			<div class="spinner"></div>
			<div class="loading-text">{{ text }}</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		watch
	} from "vue";

	const props = defineProps({
		text: {
			type: String,
			default: "Loading...",
		},
		visible: {
			type: Boolean,
			default: false,
		},
	});

	const spinnerVisible = ref(props.visible);

	watch(
		() => props.visible,
		(newValue) => {
			spinnerVisible.value = newValue;
		}
	);
</script>

<style scoped>
	.spinner-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.85);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		backdrop-filter: blur(5px);
	}

	.spinner-container {
		text-align: center;
		color: #fff;
	}

	.spinner {
		border: 4px solid rgba(255, 255, 255, 0.3);
		border-top: 4px solid #e74c3c;
		border-right: 4px solid #f1c40f;
		border-bottom: 4px solid #3498db;
		border-left: 4px solid #2ecc71;
		border-radius: 50%;
		width: 60px;
		margin: 0 auto;
		height: 60px;
		animation: spin 1s linear infinite;
	}

	.loading-text {
		margin-top: 15px;
		font-size: 1.4em;
		font-weight: bold;
		letter-spacing: 1.5px;
		animation: color-change 3s infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes color-change {
		0% {
			color: #e74c3c;
		}

		25% {
			color: #f1c40f;
		}

		50% {
			color: #3498db;
		}

		75% {
			color: #2ecc71;
		}

		100% {
			color: #e74c3c;
		}
	}
</style>