const serverConfig = {
	// baseURL: "http://127.0.0.1:9999/",
	// baseURLWs: "wss://103zeai.cn:9999/websocket/",
	baseURL: "https://103zeai.cn:9999/",

	baseURLWs: "wss://103zeai.cn:9999/websocket/",
	// baseURL: "http://localhost:9999/",

	userTokenAuthoriaztipn: true //是否开启token的验证
}

export default serverConfig;