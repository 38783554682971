import serverAxios from "..";

// 查询所有订单以及商品
export const getOrderListByPage = (page, pageSize, id) => {
	return serverAxios({
		method: "get",
		url: "sysOrder/getOrderListByPage/" + page + "/" + pageSize + "/" + id
	})
}


// 发货
export const deliverGoodsByAdm = (data) => {
	return serverAxios({
		method: "post",
		url: "sysOrder/deliverGoodsByAdm",
		data: data
	})
}


// 决绝退款
export const refusalOfRefundByAdm = (data) => {
	return serverAxios({
		method: "post",
		url: "sysOrder/refusalOfRefundByAdm",
		data: data
	})
}


// 退款操作
export const applyRefund = (data) => {
	return serverAxios({
		method: "post",
		url: "applyRefund",
		data: data
	})
}


// 管理员删除订单
export const delOrderById = (id, status, phone) => {
	return serverAxios({
		method: "get",
		url: "sysOrder/delOrderById/" + id + "/" + status + "/" + phone
	})
}


// 查询订单
export const selectOrderByDetail = (data) => {
	return serverAxios({
		method: "post",
		url: "sysOrder/selectOrderByDetail",
		data: data
	})
}


// 手机端订单管理
export const getOrderByStatus = (data) => {
	return serverAxios({
		method: "get",
		url: "sysOrder/getOrderByStatus/" + data
	})
}