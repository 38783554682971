import serverConfig from "@/https/config";

export function isHttpShow(url) {
	url.startsWith('http://') || url.startsWith('https://');
}


export function isHttpUrl(url) {
	if (url == "" || url == null) {
		return
	}
	if (url.startsWith('http://') || url.startsWith('https://')) {
		return url
	}
	return serverConfig.baseURL + url

}