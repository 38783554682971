import axios from "axios";
import serverConfig from "./config";
import qs from "qs"
import router from "@/router";
import {
	ElMessage
} from "element-plus";
//常见axios实例
const serverAxios = axios.create({
	baseURL: serverConfig.baseURL, //基础请求地址
	timeout: 60000 // 请求超时设置
})

//请求拦截
serverAxios.interceptors.request.use(
	//成功拦截
	(config) => {
		let tob = localStorage.getItem("token", JSON.stringify("token"));
		if (tob == null && config.url != "user/login" && config.url !=
			"system/getSystemAll") {
			// ElMessage.error("登陆过期")
			// alert("token过期，请重新登陆")
			router.push("/401")
			return
		}
		// console.log("请求配置", config)
		// // 如果开启 token 认证
		if (serverConfig.userTokenAuthoriaztipn && config.url != "user/login" && config.url !=
			"system/getSystemAll") {
			if (config.token == undefined) {
				let token = localStorage.getItem("token", JSON.stringify("token"));
				//去除转义符
				// const cleanToken = JSON.parse(token)
				config.headers['token'] = token; // 请求头携带 token

			}

		}
		// 设置请求头
		if (!config.headers["content-type"]) { // 如果没有设置请求头
			if (config.method === 'get') {
				config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
				config.data = qs.stringify(config.data); // 序列化,比如表单数据
			} else {
				config.headers["content-type"] = "application/json"; // 默认类型
			}
		}
		//业务逻辑
		return config;
	},
	//失败拦截
	(error) => {
		console.log(error)
	}
);

//响应拦截
serverAxios.interceptors.response.use(
	(res) => {
		let token = localStorage.getItem("token", JSON.stringify("token"));
		// console.log("响应结果", res)
		if (res.data.code == 401) {
			// ElMessage.error("登陆过期")
			// alert("token过期，请重新登陆")
			router.push("/401")
		}
		// 处理自己的业务逻辑，比如判断 token 是否过期等等
		// 代码块
		return res.data;
	},
	(error) => {
		console.log(error)
		let message = "";
		if (error && error.response) {
			switch (error.response.status) {
				case 302:
					message = "接口重定向了！";
					break;
				case 400:
					message = "参数不正确！";
					break;
				case 401:
					message = "您未登录，或者登录已经超时，请先登录！";
					break;
				case 403:
					message = "您没有权限操作！";
					break;
				case 404:
					message = `请求地址出错: ${error.response.config.url}`;
					break;
				case 408:
					message = "请求超时！";
					break;
				case 409:
					message = "系统已存在相同数据！";
					break;
				case 500:
					message = "服务器内部错误！";
					break;
				case 501:
					message = "服务未实现！";
					break;
				case 502:
					message = "网关错误！";
					break;
				case 503:
					message = "服务不可用！";
					break;
				case 504:
					message = "服务暂时无法访问，请稍后再试！";
					break;
				case 505:
					message = "HTTP 版本不受支持！";
					break;
				default:
					message = "异常问题，请联系管理员！";
					break;
			}
		}
		return Promise.reject(message);
	}
);

export default serverAxios;