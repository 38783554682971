<template>
	<div class="error-page">
		<div class="aa">
			<div class="error-code">401</div>
			<div class="error-message">权限不足，请重新登录</div>
			<router-link to="/" class="return-button">返回首页</router-link>
		</div>
	</div>
</template>

<style scoped>
	.error-page {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background-color: #7c8996;
	}

	.aa {
		padding: 100px;
		background-color: #fff;
		text-align: center;
		border-radius: 10px;
	}

	.error-code {
		font-size: 100px;
		font-weight: bold;
		color: #333;
	}

	.error-message {
		font-size: 24px;
		color: #666;
		margin-top: 20px;
	}

	.return-button {
		display: inline-block;
		padding: 10px 20px;
		background-color: #4CAF50;
		color: white;
		text-decoration: none;
		border-radius: 5px;
		margin-top: 30px;
	}
</style>