import serverAxios from "..";

// 添加商户
export const addSysCommercialBy = (data) => {
	return serverAxios({
		method: "post",
		url: "sysCommercial/addSysCommercialBy",
		data: data
	})
}

// 修改商户
export const updateSysCommercialBy = (data) => {
	return serverAxios({
		method: "post",
		url: "sysCommercial/updateSysCommercialBy",
		data: data
	})
}

// 删除商户
export const delSysCommercial = (data) => {
	return serverAxios({
		method: "get",
		url: "sysCommercial/delSysCommercial/" + data
	})
}

// 查询商户
export const getSysCommercial = () => {
	return serverAxios({
		method: "get",
		url: "sysCommercial/getSysCommercial"
	})
}


// 根据商家查询商户
export const sysCommercial = (id) => {
	return serverAxios({
		method: "get",
		url: "sysCommercial/getIdByUid/" + id
	})
}