<template>
	<loadingPage :visible="show"></loadingPage>
	<div class="box" :style="{ backgroundColor: headersColor }">
		<div class="header">
			<div>
				<el-icon color="#8a8888" size="14" @click="onClick">
					<Menu />
				</el-icon>
			</div>
			<div>
				<div>
					<div class="span">
						<el-dropdown>
							<span class="el-dropdown-link">
								<el-icon color="#8a8888" size="14">
									<HomeFilled />
								</el-icon>
							</span>
							<template #dropdown>
								<el-dropdown-menu @click="navIndex">
									<el-dropdown-item>首页</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>

					<div class="span">
						<el-dropdown>
							<span class="el-dropdown-link">
								<el-icon color="#8a8888" size="14">
									<Grid />
								</el-icon>
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item @click="changeColor('default')">
										<span style="
                        background-color: #2d2d2d;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">默认</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('blackWhite')">
										<span style="
                        background-color: #2d2d2d;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">黑白</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('blueGold')">
										<span style="
                        background-color: #2c3e50;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">深蓝金色</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('greenBeige')">
										<span style="
                        background-color: #3a4f41;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">暗绿米色</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('redGray')">
										<span style="
                        background-color: #5d2e46;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">酒红银灰</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('darkGreenLightGray')">
										<span style="
                        background-color: #1b4f72;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">墨绿浅灰</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('brownBeige')">
										<span style="
                        background-color: #4b3832;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">咖啡米黄</span>
									</el-dropdown-item>
									<el-dropdown-item @click="changeColor('darkGrayBlueGray')">
										<span style="
                        background-color: #343a40;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                      ">&nbsp;</span>
										<span style="margin-left: 5px; font-size: 12px">深灰蓝灰</span>
									</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
					<div class="span">
						<el-dropdown>
							<span class="el-dropdown-link">
								<el-icon color="#8a8888" size="14">
									<FullScreen />
								</el-icon>
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item @click="toggleFullScreen">全屏</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>

					<div class="span">
						<el-dropdown>
							<span class="el-dropdown-link">
								欢迎您
								<span :style="{ color: asideColor }" style="font-weight: bold">{{ userName }}</span>
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item @click="navLog">退出</el-dropdown-item>
									<el-dropdown-item>锁屏</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
			</div>
		</div>

		<div></div>
	</div>
</template>

<script setup>
	import {
		computed,
		ref,
		defineComponent
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		Menu,
		HomeFilled,
		Fold,
		MagicStick,
		FullScreen,
	} from "@element-plus/icons-vue";

	import {
		useRouter
	} from "vue-router"

	const route = useRouter();

	import loadingPage from "../components/loadingPage.vue";

	//创建组件对象
	const MyComponent = defineComponent({
		components: {
			loadingPage,
		},
	});

	const store = useStore();

	const userName = ref(store.state.user.userName);
	const headersColor = computed(() => store.state.system.hea);
	const asideColor = computed(() => store.state.system.aside);
	// const headersColor = ref(headers.value);
	// console.log("hea");
	console.log(headersColor.value);
	const onClick = () => {
		store.commit("updateIsCollapse", !store.state.system.isCollapse);
	};

	const toggleFullScreen = () => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	const navIndex = () => {
		route.push("/main/home")
	}

	const navLog = () => {
		localStorage.clear();
		route.push("/")
	}


	const show = ref(false);
	const changeColor = (color) => {
		const headersColor = ref("");
		const menuColor = ref("");
		switch (color) {
			case "default":
				headersColor.value = "#1E222B";
				menuColor.value = "#FFF";
				break;
			case "blackWhite":
				headersColor.value = "#2D2D2D";
				menuColor.value = "#F5F5F5";
				break;
			case "blueGold":
				headersColor.value = "#2C3E50";
				menuColor.value = "#D4AF37";
				break;
			case "greenBeige":
				headersColor.value = "#3A4F41";
				menuColor.value = "#EAE3D2";
				break;
			case "redGray":
				headersColor.value = "#5D2E46";
				menuColor.value = "#C0C0C0";
				break;
			case "darkGreenLightGray":
				headersColor.value = "#1B4F72";
				menuColor.value = "#D5DBDB";
				break;
			case "brownBeige":
				headersColor.value = "#4B3832";
				menuColor.value = "#E3D5B8";
				break;
			case "darkGrayBlueGray":
				headersColor.value = "#343A40";
				menuColor.value = "#6C757D";
				break;
		}
		const params = {
			aside: headersColor.value,
			hea: menuColor.value,
		};
		store.commit("updateColor", params); // 假设有一个 mutation 来更新菜单颜色
		show.value = true;

		setTimeout(() => {
			show.value = false;
		}, 1000);
	};
</script>

<style scoped>
	.box {
		width: 100%;
		height: 100%;
	}

	:deep(.el-tooltip__trigger:focus-visible) {
		outline: unset;
	}


	.red {
		color: red;
		fill: red;
	}

	.header {
		padding: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.span {
		margin-right: 10px;
		display: inline-block;
	}

	.span:hover {
		cursor: pointer;
		transform: scale(1.3);
		border: 0;
	}
</style>