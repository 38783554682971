export default {
	state() {
		return {
			userName: "",
			authorities: [],
			id: ''
		};
	},
	mutations: {
		// 在这里定义你的mutations
		updateUser(state, newData) {
			state.userName = newData.userName;
			state.authorities = newData.authorities;
			state.id = newData.id;
		}
	},
	actions: {
		// 在这里定义你的actions
	},
	getters: {
		// 在这里定义你的getters
	}
}