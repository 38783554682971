import {
	createStore
} from "vuex";

import user from "./modules/user"
import system from "./modules/system"

//vuex-persistedstate数据持久化
import createPersistedState from "vuex-persistedstate";

const store = createStore({
	modules: {
		user,
		system
	},
	plugins: [createPersistedState('system')]
})

export default store