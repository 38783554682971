import serverAxios from "..";

// 查询所有系统标签
export const getSystemAll = () => {
	return serverAxios({
		method: "get",
		url: "system/getSystemAll"
	})
}
// 添加系统
export const addSystemByMy = (data) => {
	return serverAxios({
		method: "post",
		url: "system/addSystemByMy",
		data: data
	})
}
// 修改系统
export const updateSystemByMy = (data) => {
	return serverAxios({
		method: "post",
		url: "system/updateSystemByMy",
		data: data
	})
}


// 管理员列表
export const getAdminByAll = () => {
	return serverAxios({
		method: "get",
		url: "system/getAdminByAll"
	})
}

// 添加管理员
export const addAdminById = (data) => {
	return serverAxios({
		method: "post",
		url: "system/addAdminById",
		data: data
	})
}

// 校验用户名
export const selectUserName = (data) => {
	return serverAxios({
		method: "post",
		url: "system/selectUserName",
		data: data
	})
}

// 修改管理员
export const updateUserByAdmin = (data) => {
	return serverAxios({
		method: "post",
		url: "system/updateUserByAdmin",
		data: data
	})
}

// 删除管理员
export const deleteUserByAdmin = (data) => {
	return serverAxios({
		method: "get",
		url: "system/deleteUserByAdmin/" + data,
	})
}


// 修改管理员状态
export const updateByStatus = (data) => {
	return serverAxios({
		method: "post",
		url: "system/updateByStatus",
		data: data
	})
}