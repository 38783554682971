export default {
	state() {
		return {
			aside: "#1E222B",
			hea: "#FFF",
			isCollapse: false,
			goods: [],
			show: false
		};
	},
	mutations: {
		// 在这里定义你的mutations
		updateColor(state, newData) {
			state.aside = newData.aside,
				state.hea = newData.hea
		},
		updateIsCollapse(state, newData) {
			state.isCollapse = newData
		},
		updateGoods(state, newData) {
			state.goods = newData.goods
		},
		updateShow(state, newData) {
			state.show = newData.show
		}

	},
	actions: {
		// 在这里定义你的actions
	},
	getters: {

	}
}